<template>
  <section id="liquidity">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="DollarSignIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="bankAccountStatement" placeholder="Bank Account Statement" type="number"
              @keyup.enter="changeBankAccountStatement" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showLiqTrend" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Liquidity
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-liquidity" />
              </h4>
              <b-popover target="popover-liquidity" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="liqTrendChart" :options="liqTrendChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Liquidity development
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-liquidity-development" />
              </h4>
              <b-popover target="popover-liquidity-development" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="liquidityDevelopmentChart" :options="liquidityDevelopmentChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="liq7DaysTrend >= 0" class="mb-25 font-weight-bolder text-success">
                  +{{ liq7DaysTrend | formatCurrencyNumber }}
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-danger">
                  {{ liq7DaysTrend | formatCurrencyNumber }}
                </h2>
                <span>Next 7 days</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="liq14DaysTrend >= 0" class="mb-25 font-weight-bolder text-success">
                  +{{ liq14DaysTrend | formatCurrencyNumber }}
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-danger">
                  {{ liq14DaysTrend | formatCurrencyNumber }}
                </h2>
                <span>Next 14 days</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="liq21DaysTrend >= 0" class="mb-25 font-weight-bolder text-success">
                  +{{ liq21DaysTrend | formatCurrencyNumber }}
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-danger">
                  {{ liq21DaysTrend | formatCurrencyNumber }}
                </h2>
                <span>Next 21 days</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <b-overlay :show="showInPayments" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Expected incoming payments
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-expected-incoming-payments" />
          </h4>
          <b-popover target="popover-expected-incoming-payments" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="inPaymentsTable.currentPage"
          :items="inPaymentsTable.items" :fields="inPaymentsTable.fields" :sort-by.sync="inPaymentsTable.sortBy"
          :sort-desc.sync="inPaymentsTable.sortDesc" :sort-direction="inPaymentsTable.sortDirection"
          :filter="inPaymentsTable.filter" :filter-included-fields="inPaymentsTable.filterOn"
          @sort-changed="sortChangedInPayments" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="inPaymentsTable.currentPage" :total-rows="inPaymentsTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleInPaymentsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay :show="showOutPayments" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Expected outgoing payments
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-expected-outgoing-payments" />
          </h4>
          <b-popover target="popover-expected-outgoing-payments" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="outPaymentsTable.currentPage"
          :items="outPaymentsTable.items" :fields="outPaymentsTable.fields" :sort-by.sync="outPaymentsTable.sortBy"
          :sort-desc.sync="outPaymentsTable.sortDesc" :sort-direction="outPaymentsTable.sortDirection"
          :filter="outPaymentsTable.filter" :filter-included-fields="outPaymentsTable.filterOn"
          @sort-changed="sortChangedOutPayments" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="outPaymentsTable.currentPage" :total-rows="outPaymentsTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleOutPaymentsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay :show="showRecurringPayments" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Recurring payments
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-recurring-payments" />
          </h4>
          <b-popover target="popover-recurring-payments" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
              @click="addRecurringPaymentClicked">
              Add Recurring Payment
            </b-button>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="recurringPaymentsTable.currentPage"
          :items="recurringPaymentsTable.items" :fields="recurringPaymentsTable.fields"
          :sort-by.sync="recurringPaymentsTable.sortBy" :sort-desc.sync="recurringPaymentsTable.sortDesc"
          :sort-direction="recurringPaymentsTable.sortDirection" :filter="recurringPaymentsTable.filter"
          :filter-included-fields="recurringPaymentsTable.filterOn" @row-clicked="recurringPaymentsRowClicked">
          <template #cell(delete)="data">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
              @click="openDeleteConfirm(data.item.id)">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="recurringPaymentsTable.currentPage" :total-rows="recurringPaymentsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleRecurringPaymentsPageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-modal ref="modal-reccuring-payment-submit" cancel-variant="outline-secondary" ok-title="Submit"
        cancel-title="Close" centered :title="recurringPaymentID ? 'Update' : 'Add'" :no-close-on-backdrop="true"
        @ok="handleRecurringPaymentSubmit" @hidden="resetRecurringPaymentModal">
        <b-form @submit.prevent>
          <b-form-group label="Name" label-for="recurringPaymentName">
            <b-form-input id="recurringPaymentName" v-model="recurringPaymentName" />
          </b-form-group>
          <b-form-group label="Type" label-for="recurringPaymentType">
            <v-select id="recurringPaymentType" v-model="recurringPaymentType" label="title"
              :options="recurringPaymentTypes" />
          </b-form-group>
          <b-form-group label="Value" label-for="recurringPaymentValue">
            <b-form-input id="recurringPaymentValue" v-model="recurringPaymentValue" type="number" />
          </b-form-group>
          <b-form-group label="Payment Term" label-for="recurringPaymentDate">
            <flat-pickr id="recurringPaymentDate" v-model="recurringPaymentDate" class="form-control" />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BModal,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';
import 'echarts';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BOverlay,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BModal,
    vSelect,
    flatPickr,
    ToastificationContent,
    ECharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showLiqTrend: true,
      showLiqDev: true,
      showInPayments: true,
      showOutPayments: true,
      showRecurringPayments: true,
      bankAccountStatement: 0,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      liq7DaysTrend: 0.0,
      liq14DaysTrend: 0.0,
      liq21DaysTrend: 0.0,
      recurringPaymentTypes: ['once', 'monthly', 'quarterly', 'yearly'],
      recurringPaymentID: '',
      recurringPaymentName: '',
      recurringPaymentValue: 0.0,
      recurringPaymentDate: '',
      recurringPaymentType: '',
      queryParamsLiqTrend: {},
      queryParamsLiqDev: {},
      queryParamsInPayments: {},
      queryParamsOutPayments: {},
      queryParamsReqPayments: {},
      liqTrendChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      liquidityDevelopmentChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      inPaymentsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'payment_term',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'order_no', label: 'order no', sortable: true },
          { key: 'invoice_no', label: 'invoice no', sortable: true },
          { key: 'company_name', label: 'company name', sortable: true },
          {
            key: 'total_amount',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment',
            label: 'payment',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'will_receive',
            label: 'will receive',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment_term',
            label: 'payment term',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'shipped_date',
            label: 'shipped date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      outPaymentsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'payment_term',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'invoice_no', label: 'invoice no', sortable: true },
          { key: 'company_name', label: 'company name', sortable: true },
          {
            key: 'total_amount',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment',
            label: 'payment',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'need_to_pay',
            label: 'need to pay',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment_term',
            label: 'payment term',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'received_date',
            label: 'received date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      recurringPaymentsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'payment_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'value',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'type', label: 'type', sortable: true },
          {
            key: 'payment_date',
            label: 'payment term',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParamsLiqDev.from_date = dateRange[0];
    this.queryParamsLiqDev.to_date = dateRange[1];

    try {
      await this.getLiqTrend();
      await this.getLiquidityDevelopment();
      await this.getInPayments();
      await this.getOutPayments();
      await this.getRecurringPayments();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getLiqTrend() {
      this.showLiqTrend = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/liquidity-trend/`, this.queryParamsLiqTrend);
        const liqTrendValues = response.data.results;
        this.liqTrendChart.xAxis.data = liqTrendValues.map(item => item.date);
        this.liqTrendChart.series[0] = {
          name: 'Value',
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top',
            distance: 5,
            align: 'left',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: liqTrendValues.map(item => (item.value + parseFloat(this.bankAccountStatement)).toFixed(2)),
        };

        const today = new Date();
        const next7Date = new Date().setDate(today.getDate() + 7);
        const next14Date = new Date().setDate(today.getDate() + 14);
        const next21Date = new Date().setDate(today.getDate() + 21);
        let liq7DaysTrend = 0.0;
        let liq14DaysTrend = 0.0;
        let liq21DaysTrend = 0.0;
        liqTrendValues.forEach(item => {
          const dateParts = item.date.split('.');
          const currDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
          if (currDate <= next7Date) {
            liq7DaysTrend += parseFloat(item.value);
          }
          if (currDate <= next14Date) {
            liq14DaysTrend += parseFloat(item.value);
          }
          if (currDate <= next21Date) {
            liq21DaysTrend += parseFloat(item.value);
          }
        })
        liq7DaysTrend += parseFloat(this.bankAccountStatement);
        liq14DaysTrend += parseFloat(this.bankAccountStatement);
        liq21DaysTrend += parseFloat(this.bankAccountStatement);
        this.liq7DaysTrend = liq7DaysTrend;
        this.liq14DaysTrend = liq14DaysTrend;
        this.liq21DaysTrend = liq21DaysTrend;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showLiqTrend = false;
      }
    },
    async getLiquidityDevelopment() {
      this.showLiqDev = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/liquidity-development/`, this.queryParamsLiqDev);
        this.liquidityDevelopmentChart.xAxis.data = response.data.results.map(item => item.month_year);
        this.liquidityDevelopmentChart.series[0] = {
          name: 'Value',
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top',
            distance: 5,
            align: 'left',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: response.data.results.map(item => item.value),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showLiqDev = false;
      }
    },
    async getInPayments() {
      this.showInPayments = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/exp-in-payments/`, this.queryParamsInPayments);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.inPaymentsTable.items = results;
          this.inPaymentsTable.totalRows = results[0].count * 2;
        }
        else {
          this.inPaymentsTable.items = [];
          this.inPaymentsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showInPayments = false;
      }
    },
    async getOutPayments() {
      this.showOutPayments = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/exp-out-payments/`, this.queryParamsOutPayments);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.outPaymentsTable.items = results;
          this.outPaymentsTable.totalRows = results[0].count * 2;
        }
        else {
          this.outPaymentsTable.items = [];
          this.outPaymentsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOutPayments = false;
      }
    },
    async getRecurringPayments() {
      this.showRecurringPayments = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/recurring-payments/`, this.queryParamsReqPayments);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.recurringPaymentsTable.items = results;
          this.recurringPaymentsTable.totalRows = response.data.count * 2;
        }
        else {
          this.recurringPaymentsTable.items = [];
          this.recurringPaymentsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showRecurringPayments = false;
      }
    },
    async sortChangedInPayments(value) {
      if (value.sortDesc === true) {
        this.queryParamsInPayments.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsInPayments.ordering = value.sortBy;
      }
      await this.getInPayments();
    },
    async sortChangedOutPayments(value) {
      if (value.sortDesc === true) {
        this.queryParamsOutPayments.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsOutPayments.ordering = value.sortBy;
      }
      await this.getOutPayments();
    },
    async handleInPaymentsTablePageChange(value) {
      this.queryParamsInPayments.page = value;
      await this.getInPayments();
    },
    async handleOutPaymentsTablePageChange(value) {
      this.queryParamsOutPayments.page = value;
      await this.getOutPayments();
    },
    async handleRecurringPaymentsPageChange(value) {
      this.queryParamsReqPayments.page = value;
      await this.getRecurringPayments();
    },
    addRecurringPaymentClicked() {
      this.$refs['modal-reccuring-payment-submit'].show();
    },
    recurringPaymentsRowClicked(row) {
      this.recurringPaymentID = row.id;
      this.recurringPaymentName = row.name;
      this.recurringPaymentValue = row.value;
      this.recurringPaymentDate = row.payment_date;
      this.recurringPaymentType = row.type;
      this.$refs['modal-reccuring-payment-submit'].show();
    },
    resetRecurringPaymentModal() {
      this.recurringPaymentID = '';
      this.recurringPaymentName = '';
      this.recurringPaymentValue = 0.0;
      this.recurringPaymentDate = '';
      this.recurringPaymentType = '';
    },
    async handleRecurringPaymentSubmit() {
      const params = {
        name: this.recurringPaymentName,
        value: this.recurringPaymentValue,
        payment_date: this.recurringPaymentDate,
        type: this.recurringPaymentType,
      }

      if (this.recurringPaymentID && this.planNumbersName !== '' && this.recurringPaymentValue !== 0.0 && this.recurringPaymentDate && this.recurringPaymentType !== '') {
        await axios
          .put(`${process.env.VUE_APP_ROOT_API}/recurring-payments/${this.recurringPaymentID}/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Recurring payment has been updated');
            await this.getRecurringPayments();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else if (this.planNumbersName !== '' && this.recurringPaymentValue !== 0.0 && this.recurringPaymentDate && this.recurringPaymentType !== '') {
        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/recurring-payments/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Recurring payment created');
            await this.getRecurringPayments();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else { this.showToast('danger', 'Error!') }
    },
    openDeleteConfirm(id) {
      this.recurringPaymentID = id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios
            .delete(`${process.env.VUE_APP_ROOT_API}/recurring-payments/${this.recurringPaymentID}/`, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(async response => {
              console.log(response);
              this.showToast('danger', 'Recurring payment has been deleted');
              await this.getRecurringPayments();
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        this.recurringPaymentID = '';
      })
    },
    async changeBankAccountStatement() {
      // this.showLiqTrend = true
      // this.liqTrendChart.data.datasets[0].data = this.liqTrendChart.data.datasets[0].data.map(
      //   item => item + parseFloat(this.bankAccountStatement),
      // )
      await this.getLiqTrend();
      // this.showLiqTrend = false
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParamsLiqDev.from_date = dateRange[0];
        this.queryParamsLiqDev.to_date = dateRange[1];
        await this.getLiquidityDevelopment();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.liquidityDevelopmentChart.refresh();
        });
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
